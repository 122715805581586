<template>
  <v-container fluid tag="section">
    <HyphenDatatable
    :headers="headers"
    :config="{resource:'design', title: 'Designs', rowClickUrl:'design', enabledActions: [ 'search', 'delete','hide-delete-action']}"
    />
    <!-- <v-data-table
      :headers="headers"
      :items="designList"
      sort-by="name"
      class="elevation-1"
      @click:row="handleClick"
      :options.sync="options"
      :server-items-length="count"
      :items-per-page="10"
      :loading="loading"
    >
      <template v-slot:[`item.createdDate`]="{ item }">
        {{ formatDate(item.createdDate) }}
      </template>

      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Designsss</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
        </v-toolbar>
        <app-filter @filter-change="getDesignList"/>
      </template>

      <template v-slot:no-data> No Data </template>
    </v-data-table> -->
   
  </v-container>
</template>


<script>
import HyphenDatatable from '../component/HyphenDatatable.vue';

export default {
  components:{
    HyphenDatatable
  },

  data: () => ({
    designList: [],

    //

    headers: [
      {
        text: "Id",
        value: "id",
      },
      
      {
        text: "Title",
        align: "start",
        sortable: false,
        value: "title",
      },
      {
        text: "Inquiry Type",
        value: "inquiryType",
      },
      {
        text: "Area",
        value: "area",
      },
      {
        text: "Created Date",
        value: "createdDate",
      },
        {
        text: "Customer",
        value: "customer.name",
      },
      {
        text: "Style",
        value: "style",
      },

      {
        text: "Status",
        value: "status",
      },
    

    ],
      count: null,
    loading:false,
    options:{},
  }),

  watch: {
     options:{
       handler () {
          this.getDesignList()
        },
        deep: true,
    }
  },

  methods: {
    handleClick(args) {
      console.log(args);
      this.$router.push({ path: "/design/" + args.id });
    },
    getDesignList($event) {
      if($event?.search){
        this.options.search = $event.search
      }
      this.formatFilter
      if($event?.isDeleted){
        this.options.isDeleted = $event.isDeleted
      }
      this.loading = true;
      this.$Design
        .getDesignList(this.options)
        .then((data) => {
          this.designList = data.data.data.list;
          this.count = data.data.data.count;
          this.loading = false;
          
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
  },

  mounted() {
    // this.getDesignList();
  },
};
</script>

<style scoped>
</style>